exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-category-jsx": () => import("./../../../src/pages/category.jsx" /* webpackChunkName: "component---src-pages-category-jsx" */),
  "component---src-pages-faq-jsx": () => import("./../../../src/pages/faq.jsx" /* webpackChunkName: "component---src-pages-faq-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-product-jsx": () => import("./../../../src/pages/product.jsx" /* webpackChunkName: "component---src-pages-product-jsx" */),
  "component---src-pages-shoplist-jsx": () => import("./../../../src/pages/shoplist.jsx" /* webpackChunkName: "component---src-pages-shoplist-jsx" */),
  "component---src-pages-support-jsx": () => import("./../../../src/pages/support.jsx" /* webpackChunkName: "component---src-pages-support-jsx" */)
}

